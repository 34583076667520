export class AudioControl extends HTMLElement {
  constructor () {
    super()
    this.audio = null
  }

  static get observedAttributes () {
    return ['play']
  }

  connectedCallback () {
    this.audio = this.parentElement

    if (this.getAttribute('play') === 'playing') {
      this.audio.play()
    }
  }

  attributeChangedCallback () {
    if (this.audio === null) {
      return
    }

    if (this.getAttribute('play') === 'playing') {
      this.audio.play()
    } else {
      this.audio.pause()
    }
  }
}

export class ScrollListener extends HTMLElement {
  constructor () {
    super()
    this.threshold = parseFloat(this.getAttribute('threshold')) || 1.0
    this.rootId = this.getAttribute('root')
  }

  static get observedAttributes () {
    return ['threshold', 'root']
  }

  attributeChangedCallback (name, oldValue, newValue) {
    switch (name) {
      case 'threshold':
        this.updateThreshold(newValue)
        break
      case 'root':
        this.updateRoot(newValue)
        break
    }
  }

  updateThreshold (newValue) {
    const newThreshold = parseFloat(newValue)
    if (newThreshold === this.threshold) {
      return
    }

    this.threshold = newThreshold

    if (this.observer) {
      this.disconnectedCallback()
      this.connectedCallback()
    }
  }

  updateRoot (newRootId) {
    if (this.rootId === newRootId) {
      return
    }

    this.rootId = newRootId

    if (this.observer) {
      this.disconnectedCallback()
      this.connectedCallback()
    }
  }

  connectedCallback () {
    this.observer = this.initObserver()
    this.observer.observe(this.parentElement)
  }

  disconnectedCallback () {
    this.observer.disconnect()
  }

  initObserver () {
    const scrollToEvent = new CustomEvent('scrollto')
    const scrollAwayEvent = new CustomEvent('scrollaway')

    return new IntersectionObserver(
      entries => entries.forEach(
        entry => entry.intersectionRatio >= this.threshold
          ? this.dispatchEvent(scrollToEvent)
          : this.dispatchEvent(scrollAwayEvent)
      ),
      {
        threshold: this.threshold,
        root: document.getElementById(this.rootId),
      }
    )
  }
}
