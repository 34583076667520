import { Elm } from './Main.elm'
import { AudioControl, ScrollListener } from './js/custom-elements'
import ElmLocalStoragePorts from './js/vendor/local-storage'
import Sniffer from 'snifferjs'
import scrollSnapPolyfill from 'css-scroll-snap-polyfill'

customElements.define('audio-control', AudioControl)
customElements.define('scroll-listener', ScrollListener)

const snifferResult = Sniffer(navigator.userAgent)
snifferResult.standalone = ('standalone' in window.navigator && window.navigator.standalone)
  || window.matchMedia('(display-mode: standalone)').matches

console.log('Sniffer result:')
console.log(snifferResult)

const app = Elm.Main.init({
  node: document.querySelector('main'),
  flags: [window.innerWidth, snifferResult],
})

const localStorage = new ElmLocalStoragePorts()
localStorage.subscribe(app, null, null, null, 'responsePort')

window.addEventListener('load', () => app.ports.onLoad.send(null))

app.ports.preload.subscribe(() => {
  // use this click event-derived call to preload all queued audio, to avoid trouble playing it programmatically later
  document.querySelectorAll('audio')
    .forEach(audio => audio.load())

  document.querySelectorAll('audio.active')
    .forEach(audio => audio.play())
})

app.ports.togglePlay.subscribe(
  toPlay => document.querySelectorAll('audio.active.playing')
    .forEach(audio => toPlay ? audio.play() : audio.pause())
)

app.ports.confirmResetPlaylist.subscribe(
  message => window.confirm(message) && app.ports.resetPlaylistConfirmed.send(null)
)

scrollSnapPolyfill()

if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    navigator.serviceWorker.register('./sw.js')
      .then(() => console.log('Service Worker registered'))
      .catch(err => {
        console.warn(`Service Worker registration failed`)
        console.warn(err)
      })
  })

  app.ports.outputPlaylist.subscribe(
    playlist => navigator.serviceWorker.ready.then(
      registration => registration.active.postMessage(playlist)
    )
  )
}
